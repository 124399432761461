import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import HeroShowroomnext from '../components/hero-showroomnext';
import MediaBlockVideo from '../components/blocks/media-block-video';

const ShowroomnextPage = () => {
  const params = {
    seoTitle: 'SHOWROOMnext',
  };

  const videos = [
    {
      id: 'launchVideo',
      wistiaId: 'mhbqjw0lbh',
      title: 'Introducing SHOWROOMnext',
      body: 'The next generation of dealership management and growing your business.',
    },
    {
      id: 'managementVisualisation',
      wistiaId: 'kdq1ehhckt',
      title: 'Power at your fingertips',
      body: 'Imagine having all the intelligence you need to run your dealership brilliantly in one place. That’s the power of SHOWROOMnext with deeply integrated reporting, analytics and real time information all delivered on an intuitively designed dashboard.',
    },
    {
      id: 'timeSavingsEfficiency',
      wistiaId: '7t1vo4gldv',
      title: 'Time better spent',
      body: 'New management features and streamlined processes in SHOWROOMnext will save staff time managing and tracking deals across the business, giving them more time to build valuable and lasting customer relationships.',
    },
    {
      id: 'getOnBoard',
      wistiaId: 'xwrf9wxkal',
      title: 'Get on board',
      body: 'We’ve made it easy to transition to SHOWROOMnext<br/>with a dedicated learning centre for your team.',
    },
  ];

  videos.reverse();

  const heroVideo = videos[0];
  const bodyVideos = videos.slice(1);

  return (
    <Layout>
      <Seo
        title={params?.seoTitle || params.title}
        description={params?.seoDescription || ''}
      />
      <HeroShowroomnext
        title={heroVideo.title}
        videoId={heroVideo.wistiaId}
        tagline={heroVideo.body}
      />
      <div className="py-16 lg:py-32 space-y-16 lg:space-y-32">
        {bodyVideos.map((video, index) => {
          return (
            <MediaBlockVideo
              key={video.id}
              title={video.title}
              videoId={video.wistiaId}
              isReversed={index % 2 === 0}
            >
              <p>{video.body}</p>
            </MediaBlockVideo>
          );
        })}
      </div>
    </Layout>
  );
};

export default ShowroomnextPage;
