import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactHtmlParser from 'react-html-parser';

import WistiaVideo from '../atoms/wistia-video';

import { HeroWrapper } from './hero';

const HeroShowroomnext = ({ title, videoId, tagline, ...props }) => {
  return (
    <HeroWrapper className={cn('text-center', props.className)}>
      <div
        className={cn(
          'xl:container mobile:px-4 tablet:px-8 lg:px-16 relative',
          props.pt || 'pt-8 lg:pt-10',
          props.pb || 'pb-8 lg:pb-14'
        )}
      >
        <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8">
          <div className="col-span-12 lg:col-start-2 lg:col-span-10">
            <div className="relative">
              {videoId && <WistiaVideo id={videoId} />}
            </div>

            <div className="bg-white bg-opacity-10 shadow-hero rounded-b-[8px] lg:rounded-b-[17px] px-2 py-6 lg:px-6 lg:pt-10 lg:pb-14">
              {title && (
                <h2 className="text-h4 xs:text-h2.5-m md:text-h2.5 tracking-widerer mb-2 md:mb-0">
                  {title}
                </h2>
              )}

              {tagline && (
                <p className="text-body-md md:text-body-lg">
                  {ReactHtmlParser(tagline)}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </HeroWrapper>
  );
};

HeroShowroomnext.propTypes = {
  title: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  tagline: PropTypes.string.isRequired,
};

export default HeroShowroomnext;
