import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Atoms
import Button from '../../atoms/button';
import WistiaVideo from '../../atoms/wistia-video';

// Components
import { generateUrlFromContentModel } from '../helpers';

const MediaBlockVideo = ({ title, buttons, videoId, isReversed, ...props }) => {
  const classes = cn(
    'lg:flex lg:items-center lg:justify-between',
    isReversed ? 'lg:flex-row-reverse' : 'lg:flex-row'
  );

  return (
    <div
      className={cn(
        'xl:container mobile:px-4 tablet:px-8 lg:px-16',
        props.className
      )}
    >
      <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8">
        <div className="col-span-12 lg:col-start-2 lg:col-span-10">
          <div className={classes}>
            <div className="mb-4 lg:mb-0 lg:flex-initial w-full lg:w-2/5 relative after:bg-gradient-109 after:from-blue-navy after:to-blue-bold after:w-full after:h-[6px] after:absolute after:bottom-0 after:left-0 after:z-50">
              {videoId && <WistiaVideo id={videoId} popOver />}
            </div>

            <div className="lg:flex-initial w-full lg:w-1/2">
              {title && <h2 className="u-h4">{title}</h2>}

              <div className="mt-1.5 lg:mt-2.5 space-y-3">{props.children}</div>

              {buttons && buttons.length > 0 && (
                <div className="flex flex-wrap -mb-3 mt-5 lg:mt-8">
                  {buttons.map((button, key) => (
                    <Button
                      key={`mb-button-${key}`}
                      as={
                        (button?.internalUrl && button?.internalUrl.length) ||
                        button?.externalUrl
                          ? 'a'
                          : 'button'
                      }
                      variant={button?.variant.toLowerCase()}
                      href={
                        button?.internalUrl && button?.internalUrl.length
                          ? generateUrlFromContentModel(button?.internalUrl[0])
                          : button?.externalUrl
                      }
                      label={button?.label || button.title}
                      aria-label={button?.ariaLabel}
                      newTab={button?.opensInNewTab}
                      className="mb-3 mr-3 last:mr-0"
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MediaBlockVideo.defaultProps = {
  title: '',
  buttons: [],
  videoId: '',
  isReversed: false,
};

MediaBlockVideo.propTypes = {
  title: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      label: PropTypes.string,
      ariaLabel: PropTypes.string,
      variant: PropTypes.oneOf(['Primary', 'Secondary', 'Tertiary']),
      opensInNewTab: PropTypes.bool,
      externalUrl: PropTypes.string,
      internalUrl: PropTypes.arrayOf(PropTypes.object),
    })
  ),
  videoId: PropTypes.string,
  isReversed: PropTypes.bool,
};

export default MediaBlockVideo;
